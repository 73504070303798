<template>
    <div class="buy-status">
        <div class="block" v-if="!confirm">
            <div class="confirm" v-if="is_authorize && !rebuy">
                <div class="cf-text">Вы действительно хотите приобрести статус <a
                    style="color: #e7311f">{{ statusName }}</a>
                    на сервере
                    <a style="color: #4ad5ea">{{ serverName }}</a> ?
                </div>

                <div class="c-sm-border">
                    <div class="sm-border"/>
                </div>


                <div class="cf-btn-block">
                    <div class="cf-btn" @click="confirm = true, purchaseStatus()">Да</div>
                    <div class="rs-btn" @click="$modal.hideAll()">Нет</div>

                </div>
            </div>

            <div class="confirm" v-else-if="is_authorize && rebuy">
                <div class="cf-text">Вы действительно хотите <a style="color: #e7311f; text-decoration: underline">продлить</a>
                    статус <a style="color: #e7311f">{{ statusName }}</a>
                    на сервере
                    <a style="color: #4ad5ea">{{ serverName }}</a> ещё на 30 дней ?
                </div>

                <div class="c-sm-border">
                    <div class="sm-border"/>
                </div>

                <div class="cf-btn-block">
                    <div class="cf-btn" @click="confirm = true, purchaseStatus()">Да</div>
                    <div class="rs-btn" @click="$modal.hideAll()">Нет</div>
                </div>
            </div>
            <div class="unautorized" v-else>
                <div class="cf-text">Вы не авторизованы.
                </div>
            </div>
        </div>

        <div class="block" v-if="confirm" style="height: 60px">
            <div class="cf-text"
                 v-if="!rebuy && (response_status === 200 || response_status === 201)">Вы успешно
                приобрели статус <a
                    style="color: #e7311f">{{ statusName }}</a>
                на сервере
                <a style="color: #4ad5ea">{{ serverName }}</a>
            </div>
            <div class="cf-text"
                 v-else-if="rebuy && (response_status === 200 || response_status === 201)">Вы
                успешно
                продлили статус <a style="color: #e7311f">{{ statusName }}</a>
                на сервере
                <a style="color: #4ad5ea">{{ serverName }}</a>
            </div>
            <div v-else-if="response_status === 401 || response_status === 403">
                <div class="cf-text" v-for="error in response_errors">
                    {{ error }}
                </div>
            </div>
            <div v-else-if="response_status === 500" class="cf-text">
                Внутренняя ошибка сервера, попробуйте позже
            </div>

        </div>

    </div>
</template>


<script>
import {PreloaderMixin} from "../../mixins/PreloaderMixin";
import {ModalsCommonMixin} from "../../mixins/ModalsCommonMixin";

export default {
    name: "StatusBuyModal",
    mixins: [PreloaderMixin, ModalsCommonMixin],
    props:
        ["statusName",
            "status_id",
            "serverName",
            "rebuy",
            "is_authorize",
            "in_lk"],

    data() {
        return {
            confirm: false,
            response_status: null,
            response_errors: null
            // reset: false,
        }
    },

    methods: {
        // 401 - Не авторизован
        // 403 - Возникли проблемы (не хватает денег, уже есть более дорогой статус и т.д.)
        // 201 - Успешно
        // 200 - Успешно

        purchaseStatus() {
            this.preloaderStartModal('.buy-status')

            require('axios').post('/api/donate/status', {
                "status_id": this.status_id,
            }, {'Accept': "application/json", 'content-type': "application/json"}).then(data => {
                this.response_status = data.status;
                this.$root.update_PlayerData();

                if (this.in_lk) {
                    this.$root.$emit('lkRenewIsSuccess', 'OK')
                }
                this.preloaderStop('.buy-status')

                this.closeWithDelay()

            }).catch(err => {
                this.preloaderStop('.buy-status')

                this.response_status = err.response.status;
                try {
                    this.response_errors = err.response.data.errors._all;
                } catch (e) {
                    console.log("Ошибка сервера")
                }
            });
        },
    },

}
</script>

<style scoped lang="scss">

@import "src/assets/scss/vars";
@import "src/assets/scss/mixins";
@import "src/assets/scss/animations";

.buy-status {
    @include flex-column();
    padding: 36px 70px;
    border-radius: 5px;
    background-color: $shitty-hex-gray;
    border: 1px solid rgba(128, 128, 128, 0.13);
    font-family: $main-font;
}

.block {
    @include flexible-column();
    margin-top: 0;

    .cf-text {
        @include modals-text-blx();
    }
}

.cf-btn-block {
    @include flexible-row-sp-ev();
    margin-top: 15px;
    font-weight: 600;
}

button,
button:active,
button:focus {
    outline: none;
}

.cf-btn, .rs-btn {
    @include main-button(4px, 30px)
}

.cf-btn:hover {
    color: #00ff00;
}

.rs-btn:hover {
    color: #e7311f;
}

.c-sm-border{
    margin: 10px 0;
    width: 100%;
    height: 1px;
    @include flexible();

    .sm-border {
        width: 85%;
        height: 1px;
        @include smooth-border();
    }
}



</style>
